<template>
  <div>
    <Spinner v-if="loading" />
    <div class="container mt-2" v-else>
      <div class="row">
        <div class="col-md-9" v-if="isVisible('fontFamily')">
          <div class="form-group">
            <label for="font">Font</label>
            <select
              class="form-control"
              id="font"
              v-model="currentFont"
              :style="{ fontFamily: currentFont }"
            >
              <option
                v-for="font in fonts"
                :key="font"
                :style="{ fontFamily: font }"
              >
                {{ font }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-4" v-if="isVisible('fontSize')">
          <div class="form-group">
            <label for="fontSizes">Font Size</label>
            <select
              class="form-control"
              id="fontSizes"
              v-model="currentSize"
              :style="{ fontFamily: currentFont }"
            >
              <option v-for="size in fontSizes" :key="size" :value="size">
                {{ size }}px
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-4" v-if="isVisible('lineHeight')">
          <div class="form-group">
            <label for="lineHeight">Line Height</label>
            <select
              class="form-control"
              id="lineHeight"
              v-model="currentLineHeight"
              :style="{ fontFamily: currentFont }"
            >
              <option v-for="item in lineHeights" :key="item" :value="item">
                {{ item }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-6" v-if="isVisible('sampleContent')">
          <div class="form-group">
            <label for="Writings">Writings</label>
            <select
              class="form-control"
              id="Writings"
              :style="{ fontFamily: currentFont }"
              v-model="currentWriting"
            >
              <option value="-1">Sample Content</option>
              <option
                v-for="(writing, index) in writings"
                :value="index"
                :key="writing.ID"
              >
                {{ writing.post_title.substring(0, 40) }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <button
            class="btn btn-sm btn-success btn-block mt-sm-0 mt-lg-4"
            :disabled="isChanged || save_changes"
            @click="triggerSave"
          >
            Save Changes
          </button>
        </div>
      </div>
      <div class="row mt-sm-3 mt-2">
        <div class="col-md-12">
          <div class="document" id="FJTL_DefaultFontContainer">
            <div
              class="content"
              v-html="content"
              :style="{
                fontFamily: currentFont,
                fontSize: `${currentSize}px`,
                lineHeight: lineHeight,
              }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fonts, fontSizes } from "../data/editor-config.js";
import "../assets/css/browser.css";
import { mapGetters } from "vuex";
import Spinner from "./Spinner.vue";

export default {
  components: { Spinner },
  data() {
    return {
      loading: true,
      save_changes: false,
      fontFamily: "Lato",
      fontSize: 14,
      lineHeight: "1.5",
      writings: [],
      currentWriting: -1,
      showElements: [
        "fontFamily",
        //"fontSize",
        // "lineHeight",
        // "sampleContent",
      ],
    };
  },
  computed: {
    ...mapGetters(["getDefaults"]),
    isChanged() {
      return (
        this.currentFont === this.getDefaults.default_font_family &&
        this.currentSize === parseInt(this.getDefaults.default_font_size) &&
        this.lineHeight === this.getDefaults.default_line_height
      );
    },
    currentFont: {
      get() {
        return this.fontFamily;
      },
      set(value) {
        this.fontFamily = value;
      },
    },
    currentSize: {
      get() {
        return this.fontSize;
      },
      set(value) {
        this.fontSize = parseInt(value);
      },
    },
    currentLineHeight: {
      get() {
        return this.lineHeight;
      },
      set(value) {
        this.lineHeight = value;
      },
    },
    fontSizes() {
      return fontSizes.filter((s) => s !== "Default (14px)");
    },
    lineHeights() {
      return ["1", "1.5", "2"];
    },
    fonts() {
      return fonts;
    },
    sampleContent() {
      return `<p style="font-family:${this.currentFont};font-size: 2rem;">Sample Content</p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Netus et
          malesuada fames ac turpis egestas maecenas pharetra convallis. Sit
          amet porttitor eget dolor morbi. Ac tincidunt vitae semper quis lectus
          nulla at volutpat. Nulla aliquet enim tortor at auctor urna nunc. Eu
          consequat ac felis donec et odio. 
        </p>
        <p style="font-family:${this.currentFont};font-size: 1.75rem;">Sample Content</p>
        <p>
        Amet nulla facilisi morbi tempus iaculis urna
          id volutpat. Fermentum leo vel orci porta non. Non tellus orci ac
          auctor augue mauris augue neque. Ut tellus elementum sagittis vitae et
          leo. Quam viverra orci sagittis eu volutpat. Gravida cum sociis
          natoque penatibus. Massa id neque aliquam vestibulum morbi. Turpis
          massa sed elementum tempus egestas sed sed.
        </p>
        <p style="font-family:${this.currentFont};font-size: 1.5rem;">Sample Content</p>
        <p>
        Neque volutpat ac tincidunt vitae
          semper quis lectus. Integer vitae justo eget magna. Non blandit massa
          enim nec dui nunc mattis enim. Morbi tempus iaculis urna id volutpat
          lacus laoreet non curabitur. Orci phasellus egestas tellus rutrum
          tellus pellentesque eu. 
        </p>
        `;
    },

    content() {
      return this.writings[this.currentWriting]
        ? this.writings[this.currentWriting].post_content
        : this.sampleContent;
    },
  },
  methods: {
    isVisible(elm) {
      return this.showElements.includes(elm);
    },
    triggerSave() {
      this.save_changes = true;
      this.$store.dispatch("saveDefaultFonts", [
        this.currentFont,
        this.currentSize,
        this.lineHeight,
        ({ message }) => {
          this.save_changes = false;
          this.$toast.open({
            message,
            position: "bottom-left",
            type: "success",
          });
        },
      ]);
    },
  },
  mounted() {
    this.$store.dispatch("loadDefaults", [
      ["default_font_family", "default_font_size", "default_line_height"],
      (data) => {
        if (data.default_font_family !== "") {
          this.currentFont = data.default_font_family;
        }
        if (data.default_font_size !== "") {
          this.currentSize = parseInt(data.default_font_size);
        }
        if (data.default_line_height !== "") {
          this.lineHeight = data.default_line_height;
        }
        this.loading = false;
        this.$store.dispatch("loadLegacyJournal", [
          "1",
          (writings) => {
            this.writings = writings;
          },
        ]);
      },
    ]);
  },
};
</script>

<style scoped>
.document {
  border: 1px solid #c4c4c4;
  border-radius: 2px;

  /* Set vertical boundaries for the document editor.  */
  max-height: 590px;

  /* This element is a flex container for easier rendering. */
  flex-flow: column nowrap;

  /* Make it possible to scroll the "page" of the edited content. */
  overflow-y: scroll;
  overflow-x: scroll;
  background-color: #ddd;
  padding: 20px;
}

.content {
  width: 21.59cm;
  min-height: 27.94cm;
  background-color: #fff;

  /* Keep the "page" off the boundaries of the container. */
  padding: 2.6cm 2.6cm 2.6cm 2.6cm;

  border: 1px hsl(0, 0%, 82.7%) solid;
  border-radius: 2px;

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

  /* Center the "page". */
  margin: 20px auto 50px;
}
</style>

<style>
@import url("https://fonts.googleapis.com/css2?family=Arsenal:ital,wght@0,400;0,700;1,400;1,700&family=Cinzel:wght@400;500;600;700;800;900&family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Libre+Franklin:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Architects+Daughter&family=Shadows+Into+Light&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
</style>

<style>
#FJTL_DefaultFontContainer .content > h1,
#FJTL_DefaultFontContainer .content > h2,
#FJTL_DefaultFontContainer .content > h3,
#FJTL_DefaultFontContainer .content > h4,
#FJTL_DefaultFontContainer .content > h5,
#FJTL_DefaultFontContainer .content > h6 {
  font-family: inherit;
}
</style>
